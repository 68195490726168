<template>
  <div>
    <v-list-item class="profile-badge d-flex justify-center elevation-4 whiteDarkable">
      <v-list-item-avatar class="mr-0">
        <v-img
            v-if="user.photo"
            class="profile-icon"
            :src="user.photo || require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')"
            alt="logo"
            width="32"
        ></v-img>
        <v-progress-circular v-if="loadingPhoto && !user.photo" indeterminate color="primary"></v-progress-circular>
      </v-list-item-avatar>
      <v-list-item-content class="ml-4">
        <v-list-item-title :title="fullname">
          {{ fullname }}
        </v-list-item-title>
        <v-list-item-subtitle :title="user.emailAddress" class="light-blue--text text--lighten-3">
          {{ user.emailAddress }}
        </v-list-item-subtitle>
      </v-list-item-content>
      <v-list-item-icon item>
        <v-btn icon @click="toggleProfileMenu">
          <v-icon>mdi-chevron-down</v-icon>
        </v-btn>
      </v-list-item-icon>
    </v-list-item>

    <v-scale-transition>
      <v-card class="ma-2 elevation-8" v-if="value">
        <v-list>

          <v-list-item
              :to="{name: 'MyAccount'}"
              link :title="$t('menus.myAccount')">
            <v-list-item-icon>
              <v-icon>mdi-account</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menus.myAccount') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              :to="{name: 'About'}"
              link :title="$t('menus.about')">
            <v-list-item-icon>
              <v-icon>mdi-help-box</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menus.about') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item
              @click="$emit('logout')"
              link :title="$t('menus.logout')">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ $t('menus.logout') }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>


      </v-card>
    </v-scale-transition>
  </div>
</template>

<script>
import Vuex from "vuex"

export default {
  name: "ProfileBadge",
  props: {
    value: Boolean,
    miniDrawer: Boolean
  },
  computed: {
    ...Vuex.mapState(["informations"]),
    user() {
      return this.informations
    },
    fullname() {
      let firstname = ""
      let lastname = ""

      if (this.user.firstname) {
        firstname = this.user.firstname[0].toUpperCase() + this.user.firstname.substring(1)
      }

      if (this.user.lastname) {
        lastname = this.user.lastname.toUpperCase()
      }


      return firstname + " " + lastname
    }
  },
  data() {
    return {
      showProfileMenu: this.value,
      loadingPhoto: true,

    }
  },
  methods: {
    toggleProfileMenu() {
      this.$emit("input", !this.value)
    }
  },
  mounted() {
    let $this = this;
    setTimeout(() => {
      if (!$this.user.photo) {
        this.$store.commit("setUser", {photo: require('@/Assets/Images/Interface/Profiles/profile-man-1.svg')})
      }
      $this.loadingPhoto = false
    }, 5000)
  }
}
</script>

<style scoped>
  .profile-badge {
    height: 64px;
  }
</style>